import React from 'react';
import MaterialTable from"@material-table/core";
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import Preview from "../preview/Preview";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography/Typography";
import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScrollLoader from "../../search/results/InfiniteScrollLoader";
import InfiniteScrollEndMessage from "../../search/results/InfiniteScrollEndMessage";
import {hasAccess} from "../../common/helper";
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { ReactComponent as ExportIcon } from '../../common/images/export.svg';

const styles = theme => ({});
const tableTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides the overrides in the parent theme in app.css
    components: {

        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    paddingLeft: "0px"
                },
                root: {
                    paddingLeft: "0px"
                },
                regular: {
                    // paddingLeft: "0px"
                }
            }
        },
        MTableToolbar: {
            styleOverrides: {
                root: {
                    // paddingLeft: "0px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    //paddingLeft: "24px"
                }
            }
        },

        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.secondary.main,
        //         },
        //         colorInherit: {
        //             color: theme.palette.secondary.main
        //         }
        //     }
        // },

        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: theme.palette.secondary.main
                }
            }
        }
    }
}));

class RelatedDocumentsTable extends React.Component {

    constructor(props) {

        super(props);

        window.location.pathname.toLowerCase().includes("debug") && console.log('RelatedDocumentsTable constructor.  props = ', props);

        //Add a column for each column in workspaceConfig
        let columns = [];
        this.props.searchConfig.resultsColumns.forEach((column) => {
            if (!column.hide) {
                columns.push(column)
            }
        });

        window.location.pathname.toLowerCase().includes("debug") && console.log('columns = ', columns);

        //MaterialTable requires the following for each column: title, field
        columns.forEach((col) => {
            col.title = col.label;
            col.cellStyle= {fontSize: "13px", fontFamily: "roboto"};
            if (col.templateKey === "n/a") {
                col.field = col.metadataKey;
                col.editable = "never"
            } else {
                col.field = col.templateKey + "~" + col.metadataKey;
            }
        });

        //add data for each item in search results
        let data = [];
        props.searchResults.forEach(sr => {
            data.push(sr)
        });

        window.location.pathname.toLowerCase().includes("debug") && console.log ('columns = ', columns);

        this.state = {
            data: data,
            columns: columns
        }
    }

    componentDidUpdate(prevProps) {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('componentDidUpdate');

        if (JSON.stringify(this.state.searchResults)!== JSON.stringify(this.props.searchResults)){
            this.props.unmountComponent();
        } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('no change to search Results')
        }
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    render() {

        const actionsConfig = this.props.actionsConfig;

        window.location.pathname.toLowerCase().includes("debug") && console.log('RelatedDocumentsTable render.  props = ', this.props);

        let tableTitle = "";

        if (this.state.data.length === 1) {
            tableTitle = this.state.data.length + " related document";
        } else {
            tableTitle = this.state.data.length + " related documents";
        }

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        const showExport = actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles)
        const colsForExport = this.state.columns.filter((col) => col.field !== 'id');


        return (
            <React.Fragment>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={tableTheme}>
                        <InfiniteScroll
                            dataLength={this.props.searchResults.length}
                            next={this.props.getNextResults}
                            hasMore={hasMore}
                            loader={
                                <InfiniteScrollLoader
                                    searchReturnedCount={this.props.searchResults.length}
                                    searchTotalCount={this.props.searchTotalCount}
                                    searchLimit={this.props.searchLimit}
                                    isFetching={this.props.isFetching}
                                    nextMarker={this.props.nextMarker}
                                    getNextResults={this.props.getNextResults}
                                />
                            }
                            endMessage={
                                <InfiniteScrollEndMessage
                                    searchReturnedCount={this.props.searchResults.length}
                                    searchTotalCount={this.props.searchTotalCount}
                                    nextMarker={this.props.nextMarker}
                                />
                            }
                            // scrollableTarget="scrollableDiv1"
                        >
                            <MaterialTable
                                title={
                                    <Typography variant={"h6"}>
                                        <React.Fragment>
                                            {tableTitle}
                                            {
                                                hasMore &&
                                                <Tooltip
                                                    title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                            <span>
                                                                <Button onClick={this.props.getNextResults}
                                                                        key={"ibNext"}
                                                                        disabled={this.props.isFetching}
                                                                        color={"secondary"}
                                                                        style={{
                                                                            fontWeight: 'bold',
                                                                            minWidth: '0px',
                                                                            paddingLeft: '5px',
                                                                            paddingRight: '5px',
                                                                            paddingTop: '5px',
                                                                            fontSize: '1rem'
                                                                        }}>
                                                                    {this.props.isFetching ?
                                                                        <CircularProgress color="secondary" size={15}/> :
                                                                        "..."}
                                                                </Button>
                                                            </span>
                                                </Tooltip>
                                            }
                                        </React.Fragment>
                                    </Typography>
                                }
                                columns={this.state.columns}
                                data={this.state.data}
                                icons={{
                                    Export: () => <ExportIcon style={{height: '24px', width: '24px'}}/>
                                }}
                                options={{
                                    //exportButton: actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles),
                                    exportMenu: showExport ? [{
                                        label: 'Export PDF',
                                        exportFunc: (cols, datas) => ExportPdf(colsForExport, datas, "export")
                                    }, {
                                        label: 'Export CSV',
                                        exportFunc: (cols, datas) => ExportCsv(colsForExport, datas, "export")
                                    }]: [],

                                    paging: false,
                                    actionsCellStyle: {
                                        color: "rgba(0, 0, 0, 0.54)"
                                    }
                                }}
                                onRowClick={null}

                                detailPanel={
                                    [
                                        {
                                            tooltip: 'Preview',
                                            disabled: false,
                                            cellStyle: {width: 10, padding: 0,},
                                            render: rowData => {
                                                return (
                                                    <div style={{height: "500px"}}>
                                                        <Preview
                                                            boxDocID={rowData?.rowData?.id}
                                                            userDetails={this.props.userDetails}
                                                            showHeader={true}
                                                            actionsConfig={this.props.actionsConfig}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                        />
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: '',
                                        editTooltip: 'Edit'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Filter',
                                        searchPlaceholder: 'Filter'
                                    }
                                }}
                            />
                        </InfiniteScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </React.Fragment>
        );
    }
}

RelatedDocumentsTable.propTypes = {
    searchResults: PropTypes.array.isRequired,
    userDetails: PropTypes.object.isRequired,
    searchConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    nextMarker: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    getNextResults: PropTypes.func.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(RelatedDocumentsTable));

