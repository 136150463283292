import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import FormControl from "@mui/material/FormControl/FormControl";
import Typography from '@mui/material/Typography';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from '@mui/material/Divider';
import Moment from 'react-moment';
import Avatar from '@mui/material/Avatar';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {getColour, getErrorMessageFromResponse, getInitials} from "../../../common/helper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = theme => ({});

function DisplayComments(props) {

    const source = window.REACT_APP_COMMENT_SOURCE;

    if (props.comments){
        return(
            props.comments.map((comment,index) => {
                const autoFocus = index === (props.comments.length -1)
                const name = source ==="elastic" ? comment.created_by : comment.created_by.name
                return(
                    <React.Fragment>
                        <ListItem
                            //className={classes.button}
                            key = {"li" + comment.id}
                            autoFocus={autoFocus} //auto-scroll to end of list
                            button //needs to be a button for autofocus to work
                            style={{pointerEvents: "none"}} //to prevent hover of button highlighting
                        >
                            <ListItemIcon key = {"liIcon" + comment.id}>
                                <Avatar key = {"avatar" + comment.id} style={{backgroundColor: getColour(name)}}>{getInitials(name)}</Avatar>
                            </ListItemIcon>
                            <ListItemText
                                key = {"liText" + comment.id}
                                primary={<Typography key = {"typog" + comment.id} component="span" variant="body2" color="textPrimary">{name}</Typography>}
                                secondary={
                                    <React.Fragment key = {"fragment1" + comment.id}>
                                        <Tooltip title={new Date(comment.created_at).toString()}
                                                 style={{pointerEvents: "auto"}} //required as hover disabled for ListItem
                                        >
                                            <span><Moment key = {"moment" + comment.id} fromNow>{comment.created_at}</Moment></span>
                                        </Tooltip>
                                        <br/>
                                        {source === "elastic" ? comment.comment : comment.message}
                                    </React.Fragment>}>
                            </ListItemText>
                        </ListItem>
                        <Divider key = {"divider" + comment.id} variant="inset" component="li" />
                    </React.Fragment>
                )
            }))
    } else {
        //in case of error retrieving comments
        return(<span/>)
    }
}

DisplayComments.propTypes = {
    comments: PropTypes.array.isRequired
};

function MLCommentsDialog (props)  {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [open, setOpen] = useState(true);
    const [inputComment, setInputComment] = useState("");
    const [comments, setComments] = useState([]);
    const [isPosting, setIsPosting] = useState(false)

    useEffect(() => {
        getComments();

        //refresh comments every 30 seconds
        const interval = setInterval(() => {
            getComments()
        }, 30000);

        //At the end of the useEffect Hook, we’re returning a new function. This is the equivilent of componentWillUnmount in a React Class component.
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = () => {

        let commentsAdded = false
        if (props.comments && Array.isArray(props.comments)) {
            if (comments.length >props.comments.length) {
                //Comments added since this dialog was opened
               commentsAdded = true
            }
        }

        //clear values
        setOpen(false);
        setInputComment("");
        setComments([])

        //Added this to address issue of dialog not re-opening after initial open
        if (props.handleCloseDialog) {
            props.handleCloseDialog(commentsAdded)
        }
    };

    const getComments = async () => {

        await props.triggerRefreshAuthToken();

        let url = window.REACT_APP_BASE_URL_SCREENING + "/result/" + props.resultId + '/snippet/' + props.snippetId + "/comment";
        let request = {
            method: 'GET',
            headers: {
                "case-token": props.userDetails.caseAccessToken,
                "Authorization": "Bearer " + props.userDetails.accessToken
            }
        };

        debug && console.log("getComments:", url);

        fetch(url , request )
            .then(response => {
                debug && console.log('getComments RESPONSE: ', response);
                if (response.ok) {
                    try {
                        return response.json();
                    } catch(err) {
                        enqueueSnackbar("Error " + response.status + " retrieving comments " + response.statusText, {variant: 'error'});
                        return null
                    }
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving comments'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log("error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(result => {
                debug && console.log ('result = ', result);
                setComments(result)
            })
            .catch(e => {
                debug && console.log("getComments Exception:", e, " url:", url, "request: ", request);
                enqueueSnackbar("Error retrieving comments - " + e, {variant: 'error'});
            })
    };

   const handleOnChangeComment = event => {
        setInputComment(event.target.value)
    };


    const postComment = async () => {

        setIsPosting(true)

        await props.triggerRefreshAuthToken();
        
        const url = window.REACT_APP_BASE_URL_SCREENING + "/result/" + props.resultId + '/snippet/' + props.snippetId + "/comment";
        const body = {
            comment: inputComment,
        };
        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "case-token": props.userDetails.caseAccessToken,
                "Authorization": "Bearer " + props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        debug && console.log ("postComment url=", url, "BODY=", body, 'request: ', request);

        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    debug && console.log('postComment RESPONSE ok: ', response);
                    try {
                        return response.json();
                    } catch(err) {
                        enqueueSnackbar("Error " + response.status + " adding comment " + response.statusText, {variant: 'error'});
                        return null
                    }
                } else {
                    debug && console.log('postComment RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting comment'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log("postComment error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(result => {
                setInputComment("")
                setIsPosting(false)
                getComments(); //refresh comments
            })
            .catch(e => {
                setIsPosting(false)
                debug && console.log("postComment Exception:", e, "url:", url, "request: ", request);
                enqueueSnackbar("Error adding comment " + e , {variant: 'error'});
            });

    };

    const userName = props.userDetails.userEmail;
    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                color={"secondary"}
                sx={{position: 'absolute', right: 0, top: 0,}}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={1} style={ {height: "600px"}}>
                    <Grid item xs={12} style={{height: "100%"}}>
                        <Grid container style={{height: "100%"}}>
                            <Grid item xs={12} style={{height: "calc(100% - 170px)", overflowY: "auto",}}>
                                <List><DisplayComments comments={comments}/></List>
                            </Grid>
                            {
                                props.enableAddComment &&
                                <Grid item xs={12} style={{height: "170px", overflowY: "auto",}}>
                                    <List>
                                        <ListItem button={false}>
                                            <ListItemIcon>
                                                <Avatar
                                                    style={{backgroundColor: getColour(userName)}}>{getInitials(userName)}</Avatar>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <FormControl  variant="standard" fullWidth style={{paddingTop: '0px'}}>
                                                    {/*<InputLabel shrink htmlFor="inputComment">Comment</InputLabel>*/}
                                                    <TextField
                                                        id="inputComment" name="inputComment"
                                                        value={inputComment}
                                                        placeholder="Write a comment" type="text" margin="none"
                                                        variant="outlined" autoComplete=""
                                                        onChange={handleOnChangeComment}
                                                        autoFocus={false} // autofocus on last comment instead to ensure it scrolls to latest comment
                                                        multiline rows={3}
                                                    />
                                                </FormControl>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseDialog} variant="contained" color="grey">Close</Button>
                {
                    props.enableAddComment &&
                    <Button onClick={postComment} variant="contained" color="secondary"
                            disabled={inputComment === "" || isPosting}>Post</Button>
                }
            </DialogActions>
        </Dialog>
    );

}

MLCommentsDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    resultId: PropTypes.string.isRequired,
    snippetId: PropTypes.string.isRequired,
    enableAddComment: PropTypes.bool.isRequired
};

export default (withStyles(styles)(MLCommentsDialog));