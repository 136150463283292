import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {Grid} from "@mui/material";
import Dashboard from "./Dashboard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const INITIAL_STATE = {
    open: false
};

class DashboardDialog extends React.Component {
    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;
    }
    componentDidMount(){
        this.setState({ open: true })
    }

    handleCloseDialog = () => {
        this.setState({ open: false });
        this.props.handleCloseDialog();
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="xl"
                >

                    <IconButton
                        aria-label="close"
                        onClick={this.handleCloseDialog}
                        color={"secondary"}
                        sx={{position: 'absolute', right: 0, top: 0,}}
                    >
                        <CloseIcon />
                    </IconButton>

                    <DialogContent style={{padding: '32px'}}>
                        <Grid container
                              style={ {height: "90vh"}}>
                            <Grid item
                                  xs={12}
                                  style={{height: "100%", overflowY: "auto"}}>
                                <Dashboard dashboardUrl={this.props.dashboardUrl}/>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}
                                variant="contained"
                                color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

DashboardDialog.propTypes = {
    dashboardUrl: PropTypes.string.isRequired,
    handleCloseDialog: PropTypes.func.isRequired
};

export default DashboardDialog;