import React from 'react';
import {Grid} from "@mui/material";
import {withStyles} from '@mui/styles';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import PreviewDialog from "../../document/preview/PreviewDialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchCriteriaTooltip from "../SearchCriteriaTooltip";
import AddDocButton from "../../document/addDocument/AddDocButton";
import DownloadResults from "../../document/download/DownloadResults";
import DownloadFile from "../../document/download/DownloadFile";
import PropTypes from 'prop-types';
import {hasAccess, numberWithCommas} from "../../common/helper"
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import DisplayOptionButtons from "./DisplayOptionButtons";
import DocumentCardContent from "../../folder/documents/DocumentCardContent";
import CardHeader from "@mui/material/CardHeader";

const styles = theme => ({});

class SearchResultsDocumentsGrid extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            searchResults: props.searchResults,
            showPreview: false,
            showPreviewDocId: ""
        }

    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.state.searchResults)!== JSON.stringify(this.props.searchResults)){
            this.props.unmountComponent();
         } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('no change to search Results')
        }

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    showPreview = (id) => {
        this.setState({
            showPreviewDialog: true,
            showPreviewDocId: id
        });
    };

    handleClosePreviewDialog = () => {
        this.setState({
            showPreviewDialog: false,
            showPreviewDocId: ""
        });
    };


    render() {

        const {...other } = this.props;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResultsDocumentsTable props = ' , this.props);

        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;
        if (!this.props.searchTotalCount || this.props.searchTotalCount === 0) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount
        }

        let tableTitle = numberWithCommas(totalCountDisp) + " " +
            (totalCountDisp === 1 ?
                this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitleSingular :
                this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitlePlural);

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.documentSearch;

        const gridConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.grid;
        const gridImageConfig = gridConfig && gridConfig.image;
        const showThumbnail = gridConfig && gridConfig.showThumbnail;

        const useCustomGridImage = gridImageConfig && gridImageConfig.custom &&
            gridImageConfig.custom.templateKey && gridImageConfig.custom.templateKey !== "" &&
            gridImageConfig.custom.metadataKey && gridImageConfig.custom.metadataKey !== ""

        const customGridImageConfig  = useCustomGridImage && gridImageConfig.custom;

        const defaultGridImage = gridImageConfig && gridImageConfig.default ? gridImageConfig.default : "logo.png"

        const showCardHeader = gridConfig && gridConfig.header && gridConfig.header.templateKey && gridConfig.header.metadataKey;
        const showCardSubheader = gridConfig && gridConfig.subheader && gridConfig.subheader.templateKey && gridConfig.subheader.metadataKey;

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        //Access to Add Doc button now further restricted by roles listed in workspaceConfig.uploadConfig (if available)
        const workspaceUploadRoles =
            this.props.selectedWorkspaceConfig.uploadConfig.roles &&
            Array.isArray(this.props.selectedWorkspaceConfig.uploadConfig.roles) ?
                this.props.selectedWorkspaceConfig.uploadConfig.roles :
                [];

        const showAddDocButton = this.props.actionsConfig.addDocumentMain.enabled &&
            this.props.selectedWorkspaceConfig.uploadConfig.enabled &&
            workspaceUploadRoles.length > 0 ?
                hasAccess(this.props.selectedWorkspaceConfig.uploadConfig, this.props.userDetails.userRoles) :
                hasAccess(this.props.actionsConfig.addDocumentMain, this.props.userDetails.userRoles)

        if (this.state.searchResults.length === 0 ) {
            return (
                <div style={{paddingLeft:'16px', paddingRight:'16px'}}>
                <AppBar
                    position="sticky"
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "none",
                    }}>
                    <Toolbar disableGutters>
                        <Typography variant={"h6"} color={"textPrimary"}>
                            {tableTitle}
                            {
                                showAddDocButton &&
                                <AddDocButton
                                    {...other}
                                    parentClasses={this.props.classes}
                                    userDetails={this.props.userDetails}
                                    uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    iconClass={""}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    actionConfig={this.props.actionsConfig.addDocumentMain}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    templatesConfig={this.props.templatesConfig}
                                    actionsConfig={this.props.actionsConfig}
                                />
                            }
                        </Typography>
                    </Toolbar>
                </AppBar>
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    {
                        this.state.showPreviewDialog && this.state.showPreviewDocId &&
                        <PreviewDialog
                            parentClasses={this.props.classes}
                            boxDocID={this.state.showPreviewDocId}
                            userDetails={this.props.userDetails}
                            handleCloseDialog={this.handleClosePreviewDialog}
                            metadataConfig={this.props.metadataConfig}
                            optionsConfig={this.props.optionsConfig}
                            workspaceConfig={this.props.workspaceConfig}
                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                            actionsConfig={this.props.actionsConfig}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                        />
                    }

                    <div style={{paddingLeft:'16px', paddingRight:'16px'}}>
                        <Grid container style={{height:"100%"}}>
                            <Grid item xs={12}>
                                <AppBar
                                    position="inherit"
                                    style={{backgroundColor: "white", color: "black", boxShadow: "none",}}>
                                    <Toolbar disableGutters>
                                        <Typography variant={"h6"} color={"textPrimary"}>
                                            {tableTitle}
                                            {
                                                hasMore &&
                                                <Tooltip
                                                    title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                    <span>
                                                        <Button onClick={this.props.getNextResults}
                                                                key={"ibNext"}
                                                                color={"secondary"}
                                                                disabled={this.props.isFetching}
                                                                style={{fontWeight: 'bold', minWidth: '0px', paddingLeft: '5px', paddingRight: '5px', paddingTop: '5px', fontSize: '1rem'}}>
                                                            {this.props.isFetching ? <CircularProgress color="secondary" size={15}/> : "..."}
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            }

                                            <DisplayOptionButtons searchConfig = {searchConfig}
                                                                  showResultsAsGrid={this.props.showResultsAsGrid}
                                                                  showResultsAsTable={this.props.showResultsAsTable}
                                                                  currentDisplay={"grid"}
                                            />

                                            {
                                                this.props.actionsConfig.searchCriteriaTooltip.enabled &&
                                                <SearchCriteriaTooltip
                                                    parentClasses={this.props.classes}
                                                    metadataConfig={this.props.metadataConfig}
                                                    searchCriteriaSimple={this.props.searchCriteriaSimple}
                                                    searchCriteria={this.props.searchCriteria}
                                                    iconClass={""}
                                                />
                                            }
                                            {
                                                this.props.searchResults.length > 0 &&
                                                this.props.actionsConfig.downloadResults.enabled &&
                                                hasAccess(this.props.actionsConfig.downloadResults, this.props.userDetails.userRoles) &&
                                                <DownloadResults
                                                    userDetails={this.props.userDetails}
                                                    downloadResultsActionConfig={this.props.actionsConfig.downloadResults}
                                                    searchResults={this.props.searchResults}
                                                    fileName={this.props.folderDetails.name + ".zip"}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                />
                                            }
                                            {
                                                this.props.actionsConfig.addDocumentMain.enabled &&
                                                hasAccess(this.props.actionsConfig.addDocumentMain, this.props.userDetails.userRoles) &&
                                                this.props.selectedWorkspaceConfig.uploadConfig.enabled &&
                                                <AddDocButton
                                                    parentClasses={this.props.classes}
                                                    userDetails={this.props.userDetails}
                                                    uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.optionsConfig}
                                                    iconClass={""}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                    actionConfig={this.props.actionsConfig.addDocumentMain}
                                                    workspaceConfig={this.props.workspaceConfig}
                                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                    templatesConfig={this.props.templatesConfig}
                                                    actionsConfig={this.props.actionsConfig}
                                                />
                                            }
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                            </Grid>
                            <Grid item xs={12} style={{height: "100%", overflow: "hidden"}} >
                                    <Grid container spacing={3}>
                                        {
                                            this.props.searchResults.map(sr => {

                                                let image = useCustomGridImage && customGridImageConfig &&
                                                    sr[customGridImageConfig.templateKey + "~" + customGridImageConfig.metadataKey]
                                                image = window.location.origin + '/images/' + (image ? image : defaultGridImage)

                                                return(
                                                    <React.Fragment>

                                                        <Grid item xs={3}>

                                                            <Card
                                                                sx={{
                                                                    maxWidth: 450
                                                                    // maxWidth: showThumbnail ? 100 : 450
                                                                }}
                                                                  style={{
                                                                      //height: showThumbnail ? 'auto' : '250px',
                                                                      height: 'auto',
                                                                      backgroundColor: '#f6f6f8',
                                                                  }}>

                                                                {
                                                                    showCardHeader &&
                                                                    <CardHeader
                                                                        title={
                                                                            <span style={{overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical"}} >
                                                                                {sr[gridConfig.header.templateKey + "~" + gridConfig.header.metadataKey]}
                                                                            </span>
                                                                        }
                                                                        subheader={
                                                                            showCardSubheader ?
                                                                                <span style={{overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical"}} >
                                                                                    {sr[gridConfig.subheader.templateKey ==="n/a" ? gridConfig.subheader.metadataKey : gridConfig.subheader.templateKey + "~" + gridConfig.subheader.metadataKey]}
                                                                                </span>: ""}
                                                                    />
                                                                }
                                                                <CardActionArea onClick={() => this.showPreview(sr.id)}>

                                                                    <DocumentCardContent
                                                                        file={sr}
                                                                        image={image}
                                                                        showThumbnail = {showThumbnail}
                                                                        userDetails={this.props.userDetails}
                                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                    />
                                                                </CardActionArea>

                                                                <div style={{ display:'flex', justifyContent:'center', backgroundColor:'#f6f6f8' }}>
                                                                    <CardActions disableSpacing>
                                                                        {
                                                                            this.props.actionsConfig.downloadFile.enabled &&
                                                                            hasAccess(this.props.actionsConfig.downloadFile, this.props.userDetails.userRoles) &&

                                                                            <DownloadFile
                                                                                boxDocID={sr.id}
                                                                                fileName={sr.name}
                                                                                userDetails={this.props.userDetails}
                                                                                downloadFileActionConfig={this.props.actionsConfig.downloadFile}
                                                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                            />
                                                                        }
                                                                    </CardActions>
                                                                </div>
                                                            </Card>

                                                        </Grid>
                                                    </React.Fragment>
                                                )

                                            })
                                        }
                                    </Grid>
                                </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            );
        }
    }
}

SearchResultsDocumentsGrid.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    toggleFilterFolderDocuments: PropTypes.func,
    filterFolderDocuments: PropTypes.bool,
    isFilteringFolderDocuments: PropTypes.bool,
    toggleFilterFolderDocumentsByMetadata: PropTypes.func,
    filterFolderDocumentsByMetadata: PropTypes.bool,
    isFilteringFolderDocumentsByMetadata: PropTypes.bool,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderDocuments: PropTypes.bool,
    nextMarker: PropTypes.string,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    showResultsAsGrid: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(SearchResultsDocumentsGrid));

