import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import RenderMetadataField from "../../common/RenderMetadataField";
import FormControl from "@mui/material/FormControl";
import Typography from '@mui/material/Typography';
import FolderHeader from "./FolderHeader";
import {hasAccess} from "../../common/helper"
import Collapse from "@mui/material/Collapse";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
//import Editor from "rich-markdown-editor";  removed as generating warnings after react-scripts updata
import Box from '@mui/material/Box';
import FolderGroupMembersDisplay from "../security/FolderGroupMembersDisplay";

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperNoBorder: {
        boxShadow: 'none',
        border: '1px solid'
    },
    folderMetadata: {
       // paddingTop : theme.spacing(2),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

class FolderDetails extends React.Component {

    constructor(props) {
        super(props);

        let editAccess = false;
        if (props.actionsConfig.editFolder.enabled) {
            if (hasAccess(props.actionsConfig.editFolder, props.userDetails.userRoles)) {
                editAccess = true;
            }
        }

        this.state = {
            folderDetails: {...props.folderDetails},
            metadataHasChanged: false,
            showFolderMetadata: !props.selectedWorkspaceConfig.searchConfig.folderSearch.folderDetails.defaultCollapse,
            editAccess: editAccess,
            isSaving: false,
            //mountFolderGroupMembersDisplay: true,
            triggerRemountFolderGroupMembersDisplay: 0
        }

        this.handleOnChangeMetadata = this.handleOnChangeMetadata.bind(this);
        this.resetMetadataHasChanged = this.resetMetadataHasChanged.bind(this);
        this.remountFolderGroupMembersDisplay = this.remountFolderGroupMembersDisplay.bind(this);
        this.updateIsSaving = this.updateIsSaving.bind(this);


        console.log ('*** FolderDetails.js - Folder name=' , props.folderDetails.name ,' id=', props.folderDetails.id, 'props=', props.folderDetails)
    }

    componentDidUpdate(prevProps) {

        //const debug = window.location.pathname.toLowerCase().includes("debug")
       // debug && console.log('FolderDetails componentDidUpdate - check if folder details have changed')

        if (!this.props.folderDetails.newFolder) {  //don't unmount if new folder, to address issue with values blanking out after editing on new folder
            //don't unmount if changes have been made via ui
            if (this.state.editAccess) {
                if (!this.state.metadataHasChanged) {
                    if (JSON.stringify(this.state.folderDetails) !== JSON.stringify(this.props.folderDetails)) {
                        //debug && console.log('FolderDetails - componentDidUpdate - folder details changed so unmount component to ensure latest values displayed.')
                        this.props.unmountComponent();
                        // } else {
                        //     debug && console.log('FolderDetails - no change to folder details')
                    }
                }
            } else {
                if (JSON.stringify(this.state.folderDetails) !== JSON.stringify(this.props.folderDetails)) {
                    //debug && console.log('FolderDetails - componentDidUpdate - folder details changed so unmount component to ensure latest values displayed.')
                    this.props.unmountComponent();
                    // } else {
                    //debug && console.log('FolderDetails - no change to folder details')
                }
            }
        }

    }

    UNSAFE_componentWillMount() {
        const debug = window.location.pathname.toLowerCase().includes("debug");
        if (!this.props.folderDetails.newFolder) {
            if (this.state.editAccess) {
                //refresh metadata to ensure latest metadata is displaying
                //Note, to prevent this code running twice, ensure folder search has all metadata fields listed in workspaceConfig.searchConfig.box.metadataKeys
                debug && console.log ('FolderDetails - .....in edit mode...get latest metadata...')
                this.props.getFolderMetadata(this.props.folderDetails.id)
            }
        }
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    remountFolderGroupMembersDisplay = () => {
        this.setState((prevState) => ({
            triggerRemountFolderGroupMembersDisplay: prevState.triggerRemountFolderGroupMembersDisplay + 1 //used to trigger useEffect on groupMembers component
        }));
    }

    updateIsSaving = (isSaving) => {
        this.setState({isSaving: isSaving})
    }

    resetMetadataHasChanged = () => {
        const debug = window.location.pathname.toLowerCase().includes("debug")
        debug && console.log ('resetMetadataHasChanged')
        this.setState({metadataHasChanged: false})
    }

    handleOnChangeMetadata = (id, newValue) => {

        let val = newValue;

        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newFolderDetails = this.state.folderDetails;        //need this temporary object to ensure all values are maintained

        newFolderDetails[id] = val;

        this.setState({folderDetails : newFolderDetails});

        if (!this.state.metadataHasChanged) {
            this.setState({metadataHasChanged: true});
        }

    };

    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }));

    };

    // handleChangeMarkdown = async (id, newValue) => {
    //     //this.handleOnChangeMetadata( id, newValue);
    //
    //     //Update value on metadataFields object used to display the metadata fields
    //     let newFolderDetails = this.state.folderDetails;        //need this temporary object to ensure all values are maintained
    //
    //     newFolderDetails[id + "_markdown"] = newValue;
    //
    //     this.setState({folderDetails : newFolderDetails});
    //
    //     if (!this.state.metadataHasChanged) {
    //         this.setState({metadataHasChanged: true});
    //     }
    //
    //     return newValue
    //
    // };

    render() {

        const classes = this.props.classes;
        const editAccess = this.state.editAccess;
        const folderDetailsConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderDetails;

        const hideFolderDetails = folderDetailsConfig.hide;
        const maxGridCols = folderDetailsConfig.maxGridCols ? folderDetailsConfig.maxGridCols : 4;

        return (
            <div style={{width: "100%"}}>

                <Box
                    className={hideFolderDetails ? classes.paperNoBorder : classes.paper}
                    elevation={ hideFolderDetails ? 0 : 1}
                    style={{textAlign: 'left', border: hideFolderDetails && 'none'}}
                    border={'1px solid'} borderColor={'#E0E0E0'} boxShadow={0} borderRadius="0px">
                    <Typography variant={"h6"} color={"textPrimary"}>
                        <FolderHeader
                            parentClasses={classes}
                            folderDetails={this.state.folderDetails}
                            userDetails={this.props.userDetails}
                            actionsConfig={this.props.actionsConfig}
                            folderHeaderConfig={this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderDetails.header}
                            metadataConfig={this.props.metadataConfig}
                            showFolderActions={true}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            metadataHasChanged={this.state.metadataHasChanged}
                            resetMetadataHasChanged={this.resetMetadataHasChanged}
                            updateFolderDetailsFromWorkflowAction={this.props.updateFolderDetailsFromWorkflowAction}
                            updateFolderDetails={this.props.updateFolderDetails}
                            workflowConfig={this.props.workflowConfig.folderActions}
                            addFolderConfig={this.props.selectedWorkspaceConfig.addFolderConfig}
                            updateIsSaving={this.updateIsSaving}
                            searchConfig={this.props.selectedWorkspaceConfig.searchConfig.folderSearch}
                            optionsConfig={this.props.optionsConfig}
                            remountFolderGroupMembersDisplay ={this.remountFolderGroupMembersDisplay}
                            //unmountFolderContent={this.props.unmountFolderContent}
                        />
                        {
                            !hideFolderDetails &&
                            <div style={{display: "inline"}}>
                                <Tooltip title={this.state.showFolderMetadata ? "Hide details" : "Show details"}>
                                    <IconButton
                                        onClick={this.handleExpandCollapse("showFolderMetadata")}
                                        aria-expanded={this.state.showFolderMetadata}
                                        aria-label="show more"
                                        color="secondary"
                                        size="medium">
                                        {this.state.showFolderMetadata ? <ExpandMore/> : <ChevronRight/>}
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }

                    </Typography>

                    {
                        !hideFolderDetails &&

                        <Collapse in={this.state["showFolderMetadata"]} timeout="auto" unmountOnExit>
                            <Grid container spacing={2} xs={12} className={classes.folderMetadata}>
                                {
                                    // for each on the search results columns for folder, read the value from folderDetails and get the label from metadataConfig
                                    this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderDetails.fields.map(entry => {
                                        if (!entry.hide) {
                                            let fieldValue =
                                                entry.templateKey === "n/a" ?
                                                    this.state.folderDetails[entry.metadataKey] ? this.state.folderDetails[entry.metadataKey] : "" :
                                                    this.state.folderDetails[entry.templateKey + "~" + entry.metadataKey] ?
                                                        this.state.folderDetails[entry.templateKey + "~" + entry.metadataKey] : "";

                                            return (
                                                <Grid item
                                                      xs={entry.extraWide || fieldValue.length > 100 || entry.markdown ?
                                                          (12 * 2)/maxGridCols : 12/maxGridCols }
                                                      style={{
                                                          height: "100%",
                                                          overflowY: "auto"
                                                      }}
                                                      key={"g" + entry.templateKey + "~" + entry.metadataKey}
                                                >

                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        style={{paddingBottom: '10px', overflow: 'hidden'}}
                                                        key={"fc" + entry.templateKey + "~" + entry.metadataKey}>

                                                        {
                                                            // entry.markdown ?
                                                            //
                                                            //     <Editor
                                                            //         id={entry.templateKey + "~" + entry.metadataKey}
                                                            //         defaultValue={fieldValue}
                                                            //         value={fieldValue}
                                                            //         autoFocus={false}
                                                            //         onChange={getNewValue => {
                                                            //             const newValue = getNewValue();
                                                            //             this.handleChangeMarkdown(entry.templateKey + "~" + entry.metadataKey, newValue);
                                                            //         }}
                                                            //     /> :

                                                                <RenderMetadataField
                                                                    parentClasses={classes}
                                                                    fieldValue={fieldValue}
                                                                    metadataConfig={this.props.metadataConfig}
                                                                    optionsConfig={this.props.optionsConfig}
                                                                    metadataKey={entry.metadataKey}
                                                                    templateKey={entry.templateKey}
                                                                    forceDisable={!editAccess || entry.readOnly || this.state.isSaving}
                                                                    usage={"edit"}
                                                                    handleOnChange={this.handleOnChangeMetadata}
                                                                    formValues={this.state.folderDetails}
                                                                    userDetails={this.props.userDetails}
                                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                />
                                                        }

                                                    </FormControl>

                                                </Grid>
                                            );
                                        } else {
                                            return (<React.Fragment/>)
                                        }
                                    })
                                }
                                {
                                    this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderDetails.showFolderGroupMembers &&

                                    <Grid item
                                          xs={12}
                                          style={{
                                              height: "100%",
                                              overflowY: "auto"
                                          }}
                                          key={"folderGroupMembers"}
                                    >
                                        <FolderGroupMembersDisplay
                                            boxId={this.props.folderDetails.id}
                                            actionsConfig={this.props.actionsConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            userDetails={this.props.userDetails}
                                            folderDetails={this.props.folderDetails}
                                            triggerRemount={this.state.triggerRemountFolderGroupMembersDisplay}
                                            folderLabel={this.props.selectedWorkspaceConfig.searchConfig.folderSearch.resultsTitleSingular}
                                            remountComponent={this.remountFolderGroupMembersDisplay}
                                        />
                                    </Grid>
                                }
                            </Grid>

                        </Collapse>
                    }


                </Box>

            </div>
        );
    };
}

FolderDetails.propTypes ={
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    updateFolderDetailsFromWorkflowAction: PropTypes.func.isRequired//,
    //unmountFolderContent: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(FolderDetails));

